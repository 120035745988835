import React from 'react'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from 'design/GlobalStyles'
import ds from 'design/designSystem'
import { Helmet } from 'react-helmet'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'AW-940301997',
}

if (typeof window === 'object') {
  TagManager.initialize(tagManagerArgs)
}

interface Props {
  element: JSX.Element
}

const RootWrapper = ({ element }: Props) => {
  if (typeof window === 'object') {
    //@ts-ignore
    window.dataLayer.push({
      event: 'pageview',
    })
  }
  return (
    <>
      <div className='application'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Clínica Veterinária Derosso</title>
        </Helmet>
      </div>
      <ThemeProvider theme={ds}>
        <GlobalStyle />
        {element}
      </ThemeProvider>
    </>
  )
}

export default RootWrapper
