export default {
  colors: {
    white: '#fff',
    primary: '#0093D8',
    secondary: '#00D8B1',
    grey: '#F7F7F7',
    black: '#1C1C1C',
    gradient: {
      bottomRight: 'linear-gradient(to bottom right, #0093d8, #00d8b1)',
    },
  },
}
