// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carta-encaminhamento-tsx": () => import("./../../../src/pages/carta-encaminhamento.tsx" /* webpackChunkName: "component---src-pages-carta-encaminhamento-tsx" */),
  "component---src-pages-carta-solicitacao-tsx": () => import("./../../../src/pages/carta-solicitacao.tsx" /* webpackChunkName: "component---src-pages-carta-solicitacao-tsx" */),
  "component---src-pages-dicas-derosso-tsx": () => import("./../../../src/pages/dicas-derosso.tsx" /* webpackChunkName: "component---src-pages-dicas-derosso-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-servicos-cirurgia-tsx": () => import("./../../../src/pages/servicos/cirurgia.tsx" /* webpackChunkName: "component---src-pages-servicos-cirurgia-tsx" */),
  "component---src-pages-servicos-diagnostico-tsx": () => import("./../../../src/pages/servicos/diagnostico.tsx" /* webpackChunkName: "component---src-pages-servicos-diagnostico-tsx" */),
  "component---src-pages-servicos-especialidades-tsx": () => import("./../../../src/pages/servicos/especialidades.tsx" /* webpackChunkName: "component---src-pages-servicos-especialidades-tsx" */),
  "component---src-pages-servicos-internamento-tsx": () => import("./../../../src/pages/servicos/internamento.tsx" /* webpackChunkName: "component---src-pages-servicos-internamento-tsx" */),
  "component---src-pages-servicos-laboratorio-tsx": () => import("./../../../src/pages/servicos/laboratorio.tsx" /* webpackChunkName: "component---src-pages-servicos-laboratorio-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */),
  "component---src-pages-sobre-nos-tsx": () => import("./../../../src/pages/sobre-nos.tsx" /* webpackChunkName: "component---src-pages-sobre-nos-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/post/post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */)
}

